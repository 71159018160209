import React from "react";

//import '../../Styles/css/style.css';  // Import CSS from the styles folder

const Home = () => {

  return (
    <main>
    

      <section className="section-lg position-relative bg-cover"
      style={{ backgroundImage: 'url(images/Backgrounds/about-bg.jpg)' }} >

  <img src="images/backgrounds/about-bg-overlay.png" alt="overlay" className="overlay-image img-fluid"/>
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-lg-6 col-md-8 col-sm-7 col-8">
        <h2 className="text-white mb-4">Who We Are</h2>
        <p className="text-light mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
          incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.</p>
        <a href="about.html" className="btn btn-primary">Read More</a>
      </div>
      <div className="col-md-2 col-sm-4 col-4 text-right align-self-end">
        <a className="venobox" data-autoplay="true" data-vbtype="video"
          href="https://www.youtube.com/watch?v=jrkvirglgaQ"><i
            className="text-center icon-sm icon-box rounded-circle text-white bg-gradient-primary d-block ti-control-play"></i></a>
      </div>
    </div>
  </div>
</section>

<section className="section">
<div className="container-fluid px-0">
    <div className="row">
      <div className="col-lg-10 mx-auto text-center">
        <h2>Our Feature Works</h2>
        <div className="section-border"></div>
      </div>
    </div>

    <div className="row no-gutters shuffle-wrapper">

<div className="col-lg-4 col-md-6">

<div className="project-item">
<img src="images/project/project-1.jpg" alt="project-image" className="img-fluid w-100"/>

<div className="project-hover bg-secondary px-4 py-3">
<a href="#" className="text-white h4">Project title</a>
<a href="#"><i className="ti-link icon-xs text-white"></i></a>
</div>
</div>

<div className="project-item">

<img src="images/project/project-3.jpg" alt="project-image" className="img-fluid w-100"/>

<div className="project-hover bg-secondary px-4 py-3">
<a href="#" className="text-white h4">Project title</a>
<a href="#"><i className="ti-link icon-xs text-white"></i></a>
</div>
</div>

</div>

      <div className="col-lg-4 col-md-6">

        <div className="project-item">
          <img src="images/project/project-2.jpg" alt="project-image" className="img-fluid w-100"/>
          <div className="project-hover bg-secondary px-4 py-3">
            <a href="#" className="text-white h4">Project title</a>
            <a href="#"><i className="ti-link icon-xs text-white"></i></a>
          </div>
        </div>
      </div>
 
      <div className="col-lg-4 col-md-6">

        <div className="project-item">
          <img src="images/project/project-4.jpg" alt="project-image" className="img-fluid w-100"/>
          <div className="project-hover bg-secondary px-4 py-3">
            <a href="#" className="text-white h4">Project title</a>
            <a href="#"><i className="ti-link icon-xs text-white"></i></a>
          </div>
        </div>

        <div className="project-item">
  <img src="images/project/project-5.jpg" alt="project-image" className="img-fluid w-100"/>
  
  <div className="project-hover bg-secondary px-4 py-3">
    <a href="#" className="text-white h4">Project title</a>
    <a href="#"><i className="ti-link icon-xs text-white"></i></a>
  </div>
</div>


		
      </div>
 
    </div>
  </div>

</section>
{/* 
<section className="wa-sec">
  <div className="container section-sm overlay-secondary-half bg-cover" 
  style={{ backgroundImage: 'url(images/Backgrounds/cta-bg.jpg)' }}
  >


  <div className="row">
    <div className="col-lg-8 offset-lg-1">
      <h2 className="h-wa">Lets Start With Us</h2>
      <p className="p-wa">Lorem ipsum dolor sit amet, magna habemus ius ad</p>
      <a href="contact.html" className="btn btn-lg btn-primary">Lets talk</a>
    </div>
  </div>
</div>
</section> */}

<section className="section pb-0">
  <div className="container">
    <div className="row">
      <div className="col-lg-10 mx-auto text-center">
        <h2>Our Smart Pricing Table</h2>
        <div className="section-border"></div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
        <div className="card bottom-shape bg-secondary pt-4 pb-5">
          <div className="card-body text-center">
            <h4 className="text-white">Basic</h4>
            <p className="text-light mb-4">Besic and simple website</p>
            <p className="text-white mb-4">$ <span className="display-3 font-weight-bold vertical-align-middle">30</span></p>
            <ul className="list-unstyled mb-5">
              <li className="text-white mb-3">Mobile-Optimized Website</li>
              <li className="text-white mb-3">Powerful Website Metrics</li>
              <li className="text-white mb-3">Free Custom Domain</li>
              <li className="text-white mb-3">24/7 Customer Support</li>
              <li className="text-white mb-3">Fully Integrated E-Cormmerce</li>
              <li className="text-white mb-3">Sell unlimited Product</li>
            </ul>
            <a href="#" className="btn btn-outline-light">Try it now</a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
        <div className="card bottom-shape bg-secondary pt-4 pb-5">
          <div className="card-body text-center">
            <h4 className="text-white">Basic</h4>
            <p className="text-light mb-4">Besic and simple website</p>
            <p className="text-white mb-4">$ <span className="display-3 font-weight-bold vertical-align-middle">30</span></p>
            <ul className="list-unstyled mb-5">
              <li className="text-white mb-3">Mobile-Optimized Website</li>
              <li className="text-white mb-3">Powerful Website Metrics</li>
              <li className="text-white mb-3">Free Custom Domain</li>
              <li className="text-white mb-3">24/7 Customer Support</li>
              <li className="text-white mb-3">Fully Integrated E-Cormmerce</li>
              <li className="text-white mb-3">Sell unlimited Product</li>
            </ul>
            <a href="#" className="btn btn-outline-light">Try it now</a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
        <div className="card bottom-shape bg-secondary pt-4 pb-5">
          <div className="card-body text-center">
            <h4 className="text-white">Basic</h4>
            <p className="text-light mb-4">Besic and simple website</p>
            <p className="text-white mb-4">$ <span className="display-3 font-weight-bold vertical-align-middle">30</span></p>
            <ul className="list-unstyled mb-5">
              <li className="text-white mb-3">Mobile-Optimized Website</li>
              <li className="text-white mb-3">Powerful Website Metrics</li>
              <li className="text-white mb-3">Free Custom Domain</li>
              <li className="text-white mb-3">24/7 Customer Support</li>
              <li className="text-white mb-3">Fully Integrated E-Cormmerce</li>
              <li className="text-white mb-3">Sell unlimited Product</li>
            </ul>
            <a href="#" className="btn btn-outline-light">Try it now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    </main>
  );
};

export default Home;
