import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Header = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get('https://strapiadmin.branex.org/api/main-menu?populate=*');
        const menuData = response?.data?.data?.mybody || [];
        console.log('Menu Data:', menuData); // For debugging
        setMenuItems(menuData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchMenu();
  }, []);

  if (loading) {
    return <p>Loading Menu...</p>;
  }

  if (error) {
    return <p>Error loading menu: {error.message}</p>;
  }

  return (
    <header className="navigation fixed-top">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <Link className="navbar-brand" to="/">
          <img src="/images/Branex-Logo-1.png" alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse text-center" id="navigation">
          <ul className="navbar-nav ml-auto">
            {menuItems.length > 0 ? (
              menuItems.map((item) => (
                <li key={item.id} className="nav-item">
                  <Link className="nav-link" to={item.URL.startsWith('/') ? item.URL : `/${item.URL}`}>
                    {item.label}
                  </Link>
                </li>
              ))
            ) : (
              <li className="nav-item">
                <p className="nav-link">No menu items available</p>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
