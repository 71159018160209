import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import TeamBanner from '../../Components/Banners/TeamBanner/team';

const Hometeam = () => {
  const location = useLocation();
  const [teamMembers, setTeamMembers] = useState([]);  // State to store the team data
  const [loading, setLoading] = useState(true);  // State to manage loading

  // Fetch team members when the component mounts
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get('https://strapiadmin.branex.org/api/teams?populate=*');
        setTeamMembers(response.data.data);  // Set the fetched data to state
      } catch (error) {
        console.error('Error fetching the team members:', error);
      } finally {
        setLoading(false);  // Stop loading after data is fetched
      }
    };

    fetchTeamMembers();
    window.scrollTo(0, 0);  // Scroll to top when component loads
  }, [location.pathname]);

  // Show loading message if data is still being fetched
  if (loading) {
    return <p>Loading team members...</p>;
  }

  return (
    <main>
      {location.pathname === '/team' && <TeamBanner />}  {/* Show banner only on team page */}

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center">
              <h2>Our Team</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
              <div className="section-border"></div>
            </div>
          </div>

          <div className="row no-gutters">
            {teamMembers.map((member) => (
              <div className="col-lg-3 col-sm-6" key={member.id}>
                <div className="card hover-shadow">
                  <img
                    src={`https://strapiadmin.branex.org${member.photo?.formats?.thumbnail?.url}`}
                    className="card-img-top"
                    alt={member.name}
                  />
                  <div className="card-body text-center position-relative zindex-1">
                    <h4>
                      <Link to={`/team/${member.slug}`} className="text-dark">
                        {member.name}
                      </Link>
                    </h4>
                    <i>{member.designation?.[0]?.children?.[0]?.text || 'No designation available'}</i>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Hometeam;
