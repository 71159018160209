import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const TeamSingle = () => {
  const { slug } = useParams(); // Get slug from URL parameters
  const [member, setMember] = useState(null); // State to store member data
  const [loading, setLoading] = useState(true); // State to manage loading

  // Fetch the team member data based on the slug
  useEffect(() => {
    const fetchMember = async () => {
      setLoading(true); // Set loading to true when starting the fetch
      try {
        const response = await fetch(`https://strapiadmin.branex.org/api/teams?filters[slug]=${slug}&populate=*`);
        const data = await response.json();

        // Check if data is returned and is in the expected format
        if (data && data.data && data.data.length > 0) {
          setMember(data.data[0]); // Set the member data
        } else {
          console.error('No member found for the given slug:', slug);
        }
      } catch (error) {
        console.error('Error fetching member data:', error);
      } finally {
        setLoading(false); // Set loading to false once the fetch is complete
      }
    };

    fetchMember();
  }, [slug]); // Re-run the effect when slug changes

  // Render loading state or member details
  return (
    <>
      {/* Page Title Section */}
      <section
        className="page-title bg-cover"
        style={{ backgroundImage: 'url(../images/backgrounds/page-title.jpg)' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="display-1 text-white font-weight-bold font-primary">Our Team</h1>
            </div>
          </div>
        </div>
      </section>

      {/* Team Member Details Section */}
      <section>
        <div className="container">
          {loading ? ( // Show loading while data is being fetched
            <p>Loading...</p>
          ) : member ? ( // Check if member data is available
            <div className="row mb-100 wa-team">
              <div className="col-lg-4 col-md-6">
                <div className="bg-secondary p-4 text-center">
                  <div className="img-thumb-circle mx-auto mb-3">
                    <img
                      src={`https://strapiadmin.branex.org${member.photo?.url || ''}`} // Adjust if necessary
                      alt={member.name}
                      className="img-fluid"
                    />
                  </div>
                  <h2 className="text-white">{member.name || 'No Name Available'}</h2>
                  <p className="text-gradient-primary h4">
                    {member.designation?.[0]?.children?.[0]?.text || 'No Position Available'}
                  </p>
                  <p>{member.description || 'No description available.'}</p>
                  <a href={`/team/${member.slug}`} className="btn btn-primary">
                    View Profile
                  </a>
                </div>
              </div>

              <div className="col-lg-7 offset-lg-1 col-md-6">
                <div className="pt-5">
                  <h2>Working Skill</h2>
                  <p className="mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  {/* Skills section can be dynamic if data is available */}
                  {['HTML5', 'jQuery', 'PHP', 'User Interface'].map((skill, index) => (
                    <div className="progress-block" key={index}>
                      <h6 className="text-uppercase">{`${skill} Expertise`}</h6>
                      <div className="progress">
                        <div className="progress-bar" style={{ width: `${Math.random() * 100}%` }}> {/* Random skill percentage for demo */}
                          <span className="skill-number text-dark font-weight-bold"><span className="count">{Math.round(Math.random() * 100)}</span>%</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <p>Member not found.</p> // Message if no member is found
          )}

          <div className="row">
            <div className="col-lg-8">
              <div className="row border-bottom mb-4">
                <div className="col-sm-6">
                  <h4 className="mb-4">Contact Information</h4>
                  <ul className="list-unstyled">
                    <li className="mb-3"><i className="ti-mobile mr-3"></i>+88 525 325 22</li>
                    <li className="mb-3"><i className="ti-email mr-3"></i>philipwilson@agen.com</li>
                    <li className="mb-3"><i className="ti-flag-alt mr-3"></i>2/A Southampton, Sandiago, USA.</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <h4 className="mb-4">Follow Me</h4>
                  <ul className="list-inline social-icons">
                    <li className="list-inline-item"><a href="#"><i className="ti-facebook"></i></a></li>
                    <li className="list-inline-item"><a href="#"><i className="ti-twitter-alt"></i></a></li>
                    <li className="list-inline-item"><a href="#"><i className="ti-linkedin"></i></a></li>
                  </ul>
                </div>
              </div>
              <h4>Work History & Feedback</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis.</p>
            </div>
            <div className="col-lg-4">
              <div className="bg-secondary p-4">
                <ul className="list-unstyled">
                  <li className="mb-4">
                    <p className="text-white mb-1">Projects Done</p>
                    <strong className="text-white">32</strong>
                  </li>
                  <li className="mb-4">
                    <p className="text-white mb-1">Success Rate</p>
                    <strong className="text-white">92%</strong>
                  </li>
                  <li className="mb-4">
                    <p className="text-white mb-1">Experience Of</p>
                    <strong className="text-white">10 years</strong>
                  </li>
                  <li className="mb-4">
                    <p className="text-white mb-1">From</p>
                    <strong className="text-white">San Diago. NY</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamSingle;
