import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Banner = () => {
    const [data, setData] = useState(null);
    const [setLoading] = useState(true); // Add loading state

    useEffect(() => {
        // Fetch data from the API
        axios.get('https://strapiadmin.branex.org/api/my-home?populate=*')
            .then(response => {
                console.log('Fetched data:', response.data); // Check response structure
                setData(response.data.data); // Store the response data
            })
            .catch(error => {
                console.error("There was an error fetching the data!", error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after fetching
            });
    }, []);

   
    if (!data) {
        console.log('No data found');
        return ; // Handle case where no data is returned
    }

    // Access the properties directly from data
    const { name, imgme } = data; // Adjusted: directly from data since attributes is not present

    return (
        <section
            className="banner bg-cover position-relative d-flex justify-content-center align-items-center"
            style={{
                backgroundImage: `url(https://strapiadmin.branex.org${imgme.formats.large.url})`, // Set background image
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh' // Adjust height as needed
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="display-1 text-white font-weight-bold font-primary">
                            {name} {/* Replace with dynamic name */}
                        </h1>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
