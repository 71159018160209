import React from "react";

const weknow = () => {

  return (
    <main>
    
      <section className="section bg-secondary position-relative">
        <div className="bg-image overlay-secondary">
          <img src="images/feature.jpg" alt="bg-image" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <div className="row align-items-center">
                <div className="col-lg-4 mb-4 mb-lg-0">
                  <img src="images/feature.jpg" alt="feature-image" className="img-fluid" />
                </div>
                <div className="col-lg-7 offset-lg-1">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="text-white">We know What Bait to Use</h2>
                      <div className="section-border ml-0"></div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="media">
                        <i className="icon text-gradient-primary ti-vector mr-3"></i>
                        <div className="media-body">
                          <h4 className="text-white">User Experience</h4>
                          <p className="text-light">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="media">
                        <i className="icon text-gradient-primary ti-layout mr-3"></i>
                        <div className="media-body">
                          <h4 className="text-white">Responsive Layout</h4>
                          <p className="text-light">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="media">
                        <i className="icon text-gradient-primary ti-headphone-alt mr-3"></i>
                        <div className="media-body">
                          <h4 className="text-white">Digital Solutions</h4>
                          <p className="text-light">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="media">
                        <i className="icon text-gradient-primary ti-ruler-pencil mr-3"></i>
                        <div className="media-body">
                          <h4 className="text-white">Bootstrap 4x</h4>
                          <p className="text-light">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     

    </main>
  );
};

export default weknow;
