import React from 'react';
import Form from '../ContactForm'; // No need to specify 'index.js', just point to the folder


const Footer = () => {

  return (

    <div className="bg-secondary position-relative">

  <img src="../images/backgrounds/map.png" className="img-fluid overlay-image" alt=""/>

  <div className="section">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-3 col-6">
          <h4 className="text-white mb-5">About</h4>
          <ul className="list-unstyled">
            <li><a href="#" className="text-light d-block mb-3">Service</a></li>
            <li><a href="#" className="text-light d-block mb-3">Conatact</a></li>
            <li><a href="#" className="text-light d-block mb-3">About us</a></li>
            <li><a href="#" className="text-light d-block mb-3">Blog</a></li>
            <li><a href="#" className="text-light d-block mb-3">Support</a></li>
          </ul>
        </div>
        <div className="col-md-3 col-6">
          <h4 className="text-white mb-5">Company</h4>
          <ul className="list-unstyled">
            <li><a href="#" className="text-light d-block mb-3">Service</a></li>
            <li><a href="#" className="text-light d-block mb-3">Conatact</a></li>
            <li><a href="#" className="text-light d-block mb-3">About us</a></li>
            <li><a href="#" className="text-light d-block mb-3">Blog</a></li>
            <li><a href="#" className="text-light d-block mb-3">Support</a></li>
          </ul>
        </div>
        <div className="col-md-6">
          <div className="bg-white p-4">
            <h3>Contact us</h3>
            <br/>
    <Form/>
            

          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="pb-4">
    <div className="container">
      <div className="row align-items-center">
       
        <div className="col-md-12">
          <ul className="list-inline text-md-right text-center">
            <li className="list-inline-item"><a className="d-block p-3 text-white" href="#"><i className="ti-facebook"></i></a></li>
            <li className="list-inline-item"><a className="d-block p-3 text-white" href="#"><i className="ti-twitter-alt"></i></a></li>
            <li className="list-inline-item"><a className="d-block p-3 text-white" href="#"><i className="ti-instagram"></i></a></li>
            <li className="list-inline-item"><a className="d-block p-3 text-white" href="#"><i className="ti-github"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>



  );
};

export default Footer;
