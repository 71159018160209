import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Styles/css/venobox.css';
import './Styles/css/themify-icons.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, About, Team, TeamSingle, OurServicesBox, BlogList, BlogDetails } from './Pages';  // Import components for routing
import { HomeBanner, Header, Footer, ServiceCTA, Weknow } from "./Components";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <Header />
      <Routes>
        {/* Home Route */}
        <Route 
          path="/" 
          element={
            <>
              <HomeBanner />
              <ServiceCTA />
              <OurServicesBox />
              <Weknow />
              <BlogList /> {/* Show BlogList on the Home page */}
            </>
          } 
        />
        
        {/* About Route */}
        <Route 
          path="/about"
          element={
            <>
              <About /> {/* About Page */}
              
            </>
          } 
        />

        {/* Team Route */}
        <Route 
          path="/team"
          element={
            <>
              <Team /> {/* Team Page */}
            </>
          } 
        />

        {/* Team member single Route */}
        <Route 
          path="/team/:slug" 
          element={
            <>
              <TeamSingle /> {/* Single Team Member Page */}            </>
          } 
        />
        
        {/* Blog List Route */}
        <Route 
          path="/blog" 
          element={
            <>              <BlogList /> {/* Blog List Page */}
            </>
          } 
        />
        
        {/* Blog Details Route */}
        <Route 
          path="/blog/:slug" 
          element={
            <>
              <BlogDetails /> {/* Blog Details Page */}
            </>
          } 
        />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
