import React, { useState } from 'react';
import axios from 'axios';
import { notification } from "antd";


const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formattedData = {
      data: {
        Fullname: formData.name,
        Email: formData.email,
        Message: formData.message
      }
    };
  
    try {
      const response = await axios.post('http://localhost:1337/api/contact-forms', formattedData);
  
      if (response.status === 201) {
        setConfirmationMessage('Message sent successfully!');
       // notification.success('thank you for your message');
        setFormData({ name: '', email: '', message: '' }); // Reset the form
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      //notification.error("There was an error submitting the form!");
      notification.error({
        message: 'Error',
        description: 'There was an error submitting the form!',
      });
      setConfirmationMessage('There was an error sending your message. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Full Name</label>
      <input
        type="text"
        id="name"
        name="name"
        className="form-control mb-4 px-0"
        value={formData.name}
        onChange={handleChange}
        required
      />

      <label htmlFor="email">Email Address</label>
      <input
        type="email"
        id="email"
        name="email"
        className="form-control mb-4 px-0"
        value={formData.email}
        onChange={handleChange}
        required
      />

      <label htmlFor="message">Message</label>
      <textarea
        name="message"
        id="message"
        className="form-control mb-4 px-0"
        value={formData.message}
        onChange={handleChange}
        required
      />

      <button className="btn btn-primary" type="submit">
        Send
      </button>

      {confirmationMessage && (
        <div className="mt-3 alert alert-info">
          {confirmationMessage}
        </div>
      )}
    </form>
  );
};

export default ContactForm;
