import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BlogBanner = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [setError] = useState(null); // Track errors

    useEffect(() => {
        // Fetch data from the API
        axios.get('https://strapiadmin.branex.org/api/blog-banner?populate=*')
            .then(response => {
                console.log('Fetched data:', response.data); // Check the entire response structure
                setData(response.data.data); // Store the response data
            })
            .catch(err => {
                console.error("There was an error fetching the data!", err);
                setError(err); // Set the error state
            })
            .finally(() => {
                setLoading(false); // Set loading to false after fetching
            });
    }, []);

    if (loading) return <p>Loading...</p>; // Display loading state

    // Check if data is available
    if (!data) {
        console.log("Data is null or undefined"); // Debugging log
        return <p>Error: Data not available</p>; // Handle case where data is null
    }

    // Access the properties directly from data
    const { Title, BannerImage } = data; // Destructure Title and BannerImage from data

    // Check if BannerImage is available
    if (!BannerImage) {
        return <p>Error: Banner image not available</p>; // Handle case where BannerImage is undefined
    }

    return (
        <section
            className="page-title bg-cover"
            style={{
                backgroundImage: `url(https://strapiadmin.branex.org${BannerImage.formats.large.url})`, // Set background image
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="display-1 text-white font-weight-bold font-primary">
                            {Title} {/* Display dynamic Title */}
                        </h1>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default BlogBanner;
