import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const BlogDetails = () => {
  const { slug } = useParams(); // Get slug from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      if (!slug) return; // Prevent API call if slug is not available
      setLoading(true); // Set loading state

      try {
        const response = await axios.get(`https://strapiadmin.branex.org/api/blogs?filters[slug][$eq]=${slug}&populate=*`);
        console.log('Blog Response:', response.data); // Log the full response

        const blogData = response.data.data[0];

        if (blogData) {
          setBlog(blogData); // Set the full blog data
        } else {
          setError('Blog not found.');
        }
      } catch (err) {
        console.error('Error fetching blog:', err);
        setError('Error fetching data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]); // Only re-run the effect if slug changes

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!blog) return <p>Blog not found.</p>;

  return (
    <>
      {/* Header Section */}
      <section
  className="page-title bg-cover blogdetails"
  style={{ backgroundImage: `url(https://strapiadmin.branex.org${blog.blog_image.url})` }} // Use the blog image URL
>
  <div className="container">
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="display-1 text-white font-weight-bold font-primary">{blog.blog_title}</h1>
      </div>
    </div>
  </div>
</section>


      {/* Blog Content */}
      <section className="section blogme">
        <div className="container">
          <h2>{blog.blog_title}</h2>

          {/* Rendering paragraphs from the 'text' field */}
          {blog.text && Array.isArray(blog.text) ? (
            blog.text.map((paragraph, index) => (
              <p key={index}>
                {paragraph.children.map((child, childIndex) => (
                  <span key={childIndex}>{child.text}</span>
                ))}
              </p>
            ))
          ) : (
            <p>{blog.text}</p> // If it's not an array, render it directly
          )}

          {/* Fetch and display blog image if exists */}
          {blog.blog_image?.url && (
            <img
              src={`https://strapiadmin.branex.org${blog.blog_image.url}`} // Access the URL directly
              alt={blog.blog_title}
              className="img-fluid"
            />
          )}
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
