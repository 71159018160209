import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ServiceBoxes = () => {
  const [serviceBoxes, setServiceBoxes] = useState([]);
  const [setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServiceBoxes = async () => {
      try {
        const response = await axios.get('https://strapiadmin.branex.org/api/service-boxes'); // Use live server URL

        // Directly set the serviceBoxes state without accessing attributes
        setServiceBoxes(response.data.data);
      } catch (err) {
        console.error('Fetch error:', err); // Log error for debugging
        setError(err); // Set error if fetching fails
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchServiceBoxes();
  }, []);


  if (error) return <p>Error fetching data: {error.message}</p>; // Show error message

  return (
    <section className="section padgremovtop">
      <div className="container">
        <div className="row">
          {serviceBoxes.map((box, index) => (
            <div className="col-lg-4 mb-4 mb-lg-0" key={box.id}> {/* Use box.id as key for better performance */}
              <div className={`card hover-bg-secondary shadow py-4 ${index === 0 ? 'active' : ''}`}>
                <div className="card-body text-center">
                  <div className="position-relative">
                    <i className="icon-lg icon-box bg-gradient-primary rounded-circle ti-palette mb-5 d-inline-block text-white"></i>
                  </div>
                  <h4 className="mb-4">{box.title}</h4>
                  {/* Check if description exists and has children before accessing */}
                  <p>
                    {box.description && box.description.length > 0 && box.description[0]?.children[0]?.text ? 
                      box.description[0].children[0].text : 
                      'No description available.'}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceBoxes;
