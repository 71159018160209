import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ServiceCTA = () => {
  const [ctaData, setCtaData] = useState(null);
  const [setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCTAData = async () => {
      try {
        const response = await axios.get('https://strapiadmin.branex.org/api/service-cta');

        setCtaData(response.data.data); // Set CTA data
      } catch (err) {
        setError(err); // Set error if fetching fails
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchCTAData();
  }, []);

  if (error) return <p>Error fetching data: {error.message}</p>; // Show error message

  // Ensure that ctaData is defined and has the expected structure
  if (!ctaData) return <p>No data available.</p>; // Fallback message if no data

  return (
    <section className="section padgremov">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto text-center">
            <h2 className="section-title">{ctaData.heading}</h2>
            <br />
            <br />
            <div className="lead">
              {Array.isArray(ctaData.paragraph) && ctaData.paragraph.length > 0 ? (
                ctaData.paragraph.map((para, index) => (
                  <p key={index}>
                    {para.children.map((child, childIndex) => (
                      <span key={childIndex}>{child.text}</span>
                    ))}
                  </p>
                ))
              ) : (
                <p>No paragraph data available.</p> // Fallback message if no paragraphs
              )}
            </div>
            <div className="section-border"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceCTA;
