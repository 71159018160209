import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import BlogBanner from "../../Components/Banners/BlogBanner/blog";
const Blog = () => {
  const location = useLocation();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://strapiadmin.branex.org/api/blogs?populate=blog_image');
        setBlogs(response.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching blogs: {error.message}</div>;

  return (
<main>

{location.pathname === '/blog' && <BlogBanner/>}

    <section className="section waq">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto text-center">
            <h2>Latest News</h2>
            <div className="section-border"></div>
          </div>
        </div>
        <div className="row">
          {blogs.map((blog) => (
            <div className="col-lg-4 col-md-6 mb-4" key={blog.id}>
              <article className="card h-100">
                <img 
                  src={blog.blog_image ? `https://strapiadmin.branex.org${blog.blog_image.url}` : 'fallback-image-url.jpg'} 
                  alt="post-thumb" 
                  className="card-img-top" 
                />
                <div className="card-body">
                  <time className="text-muted">{new Date(blog.createdAt).toLocaleDateString()}</time>
                  <Link to={`/blog/${blog.slug}`} className="h4 card-title d-block my-3 text-dark hover-text-underline">
                    {blog.blog_title}
                  </Link>
                  <Link to={`/blog/${blog.slug}`} className="btn btn-primary">Read more</Link>
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    </section>
    </main>
  );
};

export default Blog;
