import React from "react";
import AboutBanner from "../../Components/Banners/AboutUs/About";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Team_main  from "../../Pages/Team/";
// import '../../../src/Styles/plugins/card-slider/css/style.css'

const About = () => {

  const settings = {
    dots: true,  centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      // Remove existing custom classes from all slides
      const slides = document.querySelectorAll('.slick-slide');
      slides.forEach((slide) => {
        slide.classList.remove('left-side', 'center-slide', 'right-slide');
      });
    },
    afterChange: (current) => {
      const slides = document.querySelectorAll('.slick-slide');

      slides.forEach((slide, index) => {
        // Adding classes based on the current index
        if (index === current - 1) {
          slide.classList.add('left-side'); // Previous slide
        } else if (index === current) {
          slide.classList.add('center-slide'); // Current slide
        } else if (index === current + 1) {
          slide.classList.add('right-slide'); // Next slide
        }
      });
    },
  };
  return (
    <main>
     <AboutBanner />

     <section className="section pb-0">
  <div className="container">
    <div className="row">
      <div className="col-md-6 mb-4 mb-lg-0">
        <img src="images/about/about-us.png" alt="about" className="img-fluid"/>
      </div>

    <div className="col-md-6 col-lg-5">
      <div className="progress-block">
        <h6 className="text-uppercase">HTML5 Expertise</h6>
        <div className="progress">
          <div className="progress-bar" data-percent="85" style={{ width: '85%' }}>
            <span className="skill-number text-dark font-weight-bold">
              <span className="count">85</span>%
            </span>
          </div>
        </div>
      </div>
      <div className="progress-block">
        <h6 className="text-uppercase">jQuery Expertise</h6>
        <div className="progress">
          <div className="progress-bar" data-percent="95" style={{ width: '95%' }}>
            <span className="skill-number text-dark font-weight-bold">
              <span className="count">95</span>%
            </span>
          </div>
        </div>
      </div>
      <div className="progress-block">
        <h6 className="text-uppercase">PHP Expertise</h6>
        <div className="progress">
          <div className="progress-bar" data-percent="79" style={{ width: '79%' }}>
            <span className="skill-number text-dark font-weight-bold">
              <span className="count">79</span>%
            </span>
          </div>
        </div>
      </div>
      <div className="progress-block">
        <h6 className="text-uppercase">User Interface Expertise</h6>
        <div className="progress">
          <div className="progress-bar" data-percent="90" style={{ width: '90%' }}>
            <span className="skill-number text-dark font-weight-bold">
              <span className="count">90</span>%
            </span>
          </div>
        </div>
      </div>
    </div>


    </div>
  </div>
</section>




<section className="section pb-0">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="overlay-secondary video-player">
          <img src="images/about/video-thumb.jpg" alt="video-thumb" className="img-fluid w-100"/>
          <a className="play-icon">
            <i className="text-center icon-sm icon-box-sm rounded-circle text-white bg-gradient-primary d-block ti-control-play content-center"
              data-video="https://www.youtube.com/embed/jrkvirglgaQ?autoplay=1">
              <div className="ripple"></div>
            </i>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<Team_main/>

<section className="section bg-secondary">

<div className="container">
  
  <div className="row">
    <div className="col-12 text-center">
      <h2 className="text-white mb-5">Our Client Testimonails</h2>
    </div>
  </div>

  <div className="row bg-contain" data-background="images/banner/brush.png">

    <div className="col-lg-8 col-md-10 mx-auto">
      

    <div className="slide-me">
      <Slider {...settings}>


        <div className="slide">

          <div className="card text-center">
            <div className="card-body px-5 py-4">
              <img src="images/testimonial/user-1.jpg" alt="user-1" className="img-fluid rounded-circle mb-4"/>
              <h4 className="text-secondary">Mellissa Christine</h4>
              <p>“Great work I got a lot more than what I ordered, they are very legítimas and catchy. I went for one
                of them for my brand but is always better to have more options.”</p>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="card text-center">
            <div className="card-body px-5 py-4">
              <img src="images/testimonial/user-1.jpg" alt="user-1" className="img-fluid rounded-circle mb-4"/>
              <h4 className="text-secondary">Mellissa Christine</h4>
              <p>“Great work I got a lot more than what I ordered, they are very legítimas and catchy. I went for one
                of them for my brand but is always better to have more options.”</p>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="card text-center">
            <div className="card-body px-5 py-4">
              <img src="images/testimonial/user-1.jpg" alt="user-1" className="img-fluid rounded-circle mb-4"/>
              <h4 className="text-secondary">Mellissa Christine</h4>
              <p>“Great work I got a lot more than what I ordered, they are very legítimas and catchy. I went for one
                of them for my brand but is always better to have more options.”</p>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="card text-center">
            <div className="card-body px-5 py-4">
              <img src="images/testimonial/user-1.jpg" alt="user-1" className="img-fluid rounded-circle mb-4"/>
              <h4 className="text-secondary">Mellissa Christine</h4>
              <p>“Great work I got a lot more than what I ordered, they are very legítimas and catchy. I went for one
                of them for my brand but is always better to have more options.”</p>
            </div>
          </div>
        </div>

        <div className="slide">

          <div className="card text-center">
            <div className="card-body px-5 py-4">
              <img src="images/testimonial/user-1.jpg" alt="user-1" className="img-fluid rounded-circle mb-4"/>
              <h4 className="text-secondary">Mellissa Christine</h4>
              <p>“Great work I got a lot more than what I ordered, they are very legítimas and catchy. I went for one
                of them for my brand but is always better to have more options.”</p>
            </div>
          </div>

        </div>

      
      
        </Slider>
    

        </div>
   
 


  

</div>


  </div>
</div> 
</section>


     

    </main>
  );
};

export default About;
